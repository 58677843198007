<template>
  <VueApexCharts :key="chartKey" width="800" type="line" :options="chartOptions" :series="formattedChart"></VueApexCharts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: { VueApexCharts },
  data() {
    return {
      chartKey: 0,
      formattedChart: []
    };
  },
  watch: {
    chartOptions: {
      handler() {
        this.chartKey += 1;
      },
      deep: true
    },
    chartSeries: {
      handler() {
        this.chartKey += 1;
      },
      deep: true
    }
  },
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    chartSeries: {
      type: Array,
      required: true
    },
  },
  created() {
    console.log('chartSeries', this.chartSeries);
    this.chartSeries.forEach(item => {
      const formattedData = item.data.map(value => {
        value = value /100
        return parseFloat(value).toFixed(2);
      });

      this.formattedChart.push({
        ...item,
        data: formattedData
      });
    });
    this.chartOptions.yaxis = {
      labels: {
        formatter: (value) => {
          return ` ${value.toFixed(2).replace('.', ',')}`; 
        }
      }
    };
    
  }
};
</script>
